import LinkedIn from "@mui/icons-material/LinkedIn";
import Twitter from "@mui/icons-material/Twitter";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { colors } from "theme";
import { horacioMail, linkedinLink, twitterLink, whatsappLink } from "appConstants";
import { Typography } from "@mui/material";
import { Mail } from "@mui/icons-material";

const Footer = () => {
  return (
    <NativeFooter>
      <StyledFooter container className="over-header" sx={{ padding: { xs: '5%', md: '1rem 10rem' }}}>
        <Grid item md={4} xs={12} textAlign="left" padding='30px 0px'>
          <Typography variant="h2">
            Quiénes somos
          </Typography>
          <Typography variant="body1">
            Somos una empresa de desarrollo de software con foco la accesibilidad web y hacer del mundo un lugar mejor
          </Typography>
        </Grid>
        <Grid item md={5} xs={12} display="flex" sx={{ justifyContent: { xs: 'left', md: 'right' }}} role="navigation">
          <Typography variant="h2" paddingRight={'10px'}>
            Contáctanos
          </Typography>
        </Grid>
        <Grid item md={3} xs={12} display="flex" sx={{ justifyContent: { xs: 'left', md: 'right' }}} role="navigation">
          <SpacedLink href={`mailto:${horacioMail}`} target="_blank" aria-label="whatsapp">
            <Mail/>
          </SpacedLink>
          <SpacedLink href={whatsappLink} target="_blank" aria-label="whatsapp">
            <WhatsApp/>
          </SpacedLink>
          <SpacedLink href={twitterLink} target="_blank" aria-label="twitter">
            <Twitter/>
          </SpacedLink>
          <SpacedLink href={linkedinLink} target="_blank" aria-label="linkedin">
            <LinkedIn/>
          </SpacedLink>
        </Grid>
      </StyledFooter>
    </NativeFooter>
  )
}

export default Footer;

const StyledFooter = styled(Grid)`
  background-color: ${colors.primary};
`;

const NativeFooter = styled.footer`
  .MuiTypography-root{
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
  }
`

const SpacedLink = styled(Link)`
  padding: 1rem;
`