import styled from 'styled-components';
import { colors } from '../theme';

const SkipToMain = () => <StyledAnchor id="skip-to-content-link" href="#main">Saltar al contenido principal</StyledAnchor>;

export default SkipToMain;


const StyledAnchor = styled.a`
  background: ${colors.primary};
  color: ${colors.white};
  left: -100%;
  padding: 8px;
  position: absolute;
  transition: transform 0.3s;
  :focus {
    left: 1%;
}
`;
