import LinkedIn from "@mui/icons-material/LinkedIn";
import Twitter from "@mui/icons-material/Twitter";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { colors } from "theme";
import logo from "assets/logo.svg"
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import { horacioMail, linkedinLink, twitterLink, whatsappLink } from "appConstants";
import { Mail } from "@mui/icons-material";

const pages = [
  {
    label: 'Home',
    path: '/'
  },
  {
    label: 'Blog',
    path: '/blog'
  },
]

const OverHeader = () => {
  return (
    <StyledOverHeader container className="over-header" sx={{ padding: { xs: '5%', md: '1rem 10rem' }}}>
      <Grid item xs={10}>
        <Link href={`mailto:${horacioMail}`} target="_blank" className="over-header-text" aria-label="whatsapp">
          <Mail/>{horacioMail}
        </Link>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="right">
        <Link href={whatsappLink} target="_blank" className="over-header-text" aria-label="whatsapp">
          <WhatsApp/>
        </Link>
        <Link href={twitterLink} target="_blank" aria-label="twitter">
          <Twitter/>
        </Link>
        <Link href={linkedinLink} target="_blank" aria-label="linkedin">
          <LinkedIn/>
        </Link>
      </Grid>
    </StyledOverHeader>
  )
}

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <NativeHeader>
      <OverHeader/>
      <MainHeaderContainer container className="main-header" sx={{ padding: { xs: '1% 5%', md: '1rem 10rem' }}}>
        <img src={logo} alt="" role="presentation" height="150px"/>
        <h1 style={{display: 'none'}}>Horacio</h1>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent:'right' }}>
          <IconButton
            size="large"
            aria-label="opciones"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            disableRipple
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                <Link href={page.path}>
                  {page.label}
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent:'right' } }}>
          <StyledNav>
            {pages.map((page) => (
              <Link href={page.path} style={{fontStyle: 'normal'}}>
                {page.label}
              </Link>
            ))}
          </StyledNav>
        </Box>
      </MainHeaderContainer>
    </NativeHeader>
  )
}

export default Header;

const NativeHeader = styled.header`
  .MuiTypography-root{
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
  }
  .MuiSvgIcon-root{
    color: ${colors.white};
    padding: 5px
  }
  @media (prefers-color-scheme: light) {
    .MuiTypography-root{
      color: ${colors.primary}
    }
    .over-header-text{
      color: ${colors.white};
    }
  }
`

const StyledOverHeader = styled(Grid)`
  background-color: ${colors.primary};
`;
  
const MainHeaderContainer = styled(Grid)`
  place-content: space-between;
  display: flex;
  a{
    margin: 10px;
  }
  && .MuiSvgIcon-root{
    color: ${colors.primary}
  }
`

const StyledNav = styled.nav`
  display: flex;
`