import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import accessibleImage from 'assets/web-accessibility_nobg.png';
import developmentImage from 'assets/desarrollo.jpg';
import statisticsImage from 'assets/estadistica.png';
import mediaImage from 'assets/promedio.jpg';
import styled from 'styled-components';
import { colors } from 'theme';
import { Link } from '@mui/material';
import { LinkedIn, Mail, Twitter, WhatsApp } from '@mui/icons-material';
import { whatsappLink, linkedinLink, twitterLink, wcagLink, horacioMail } from 'appConstants';

const statisticsSource = 'https://www.ciudadaccesible.cl/discapacidad-en-chile/estadisticas/#:~:text=Discapacidad%20en%20Chile%20%E2%80%93%20Estudios%20y%20Encuestas%3A&text=Estudio%20Nacional%20de%20Discapacidad%20(Endisc,es%20decir%202.836.818%20personas.)'

const WhyColumns = () =>{
  return (
    <Grid container columnSpacing={2} sx={{ padding: { xs: '1% 5%', md: '1rem 10rem' }}}>
    <Grid item xs={12} md={4}>
      <StyledTypography variant='h3' padding={'3rem'}>
        Usuarios
      </StyledTypography>
      <StyledImg src={mediaImage} role="presentation" alt=""/>
      <StyledTypography variant='body1'>
        Así como no existe una persona promedio, nuestros usuarios no son promedio
      </StyledTypography>
    </Grid>
    <Grid item xs={12} md={4}>
      <StyledTypography variant='h3' padding={'3rem'}>
        Estadísticas
      </StyledTypography>
      <StyledImg src={statisticsImage} role="presentation" alt=""/>
      <StyledTypography variant='body1'>
        Actualmente, solo en Chile al año 2015, se considera que un 16,7% de la población posee
        algún grado de discapacidad, lo que se traduce en casi 3 millones de personas, 3 millones 
        de potenciales usuarios (<Link href={statisticsSource}>fuente</Link>)
      </StyledTypography>
    </Grid>
    <Grid item xs={12} md={4}>
      <StyledTypography variant='h3' padding={'3rem'}>
        Desarrollo
      </StyledTypography>
      <StyledImg src={developmentImage} role="presentation" alt=""/>
      <StyledTypography variant='body1'>
        Diseño y desarrollo deben tener considerada toda la gamma de usuarios que potencialmente puedan usar su aplicación.
      </StyledTypography>
    </Grid>
  </Grid>
  )
}

const Services = () => {
  return (
    <Grid container sx={{ padding: { xs: '1% 5%', md: '3rem 10rem' }}}>
      <ServicesContainer item xs={12} md={6} textAlign="center" padding={'30px'}>
        <Typography variant='h3'>
          Evaluaciones
        </Typography>
        <Typography variant='body1'>
          ¿Quieres saber si tu producto es accessible? Guiados por las guías de accessibilidad de 
          la <Link href={wcagLink}>WCAG</Link> realizamos una evaluación de adherencia en tu página web y
          te entregamos un informe respecto a las fortalezas y debilidades de tu página. Además se adjuntas sugerencias de mejora.
        </Typography>
      </ServicesContainer>
      <ServicesContainer item xs={12} md={6} textAlign="center" padding={'30px'}>
        <Typography variant='h3'>
          Desarrollo
        </Typography>
        <Typography variant='body1'>
          ¿Quieres realizar una página web y estar seguro que el producto final será accessible? Envíanos tu idea y trabajemos 
          juntos para que obtengas un producto de calidad desde el primer momento.
        </Typography>
      </ServicesContainer>
      <ServicesContainer item xs={12} md={6} textAlign="center" padding={'30px'}>
        <Typography variant='h3'>
          Charlas
        </Typography>
        <Typography variant='body1'>
          ¿Te gustaría difundir un poco más la accessibilidad web? No busques más! Nosotros podemos 
          ayudarte con charlas para grupos grandes o pequeños.
        </Typography>
      </ServicesContainer>
      <ServicesContainer item xs={12} md={6} textAlign="center" padding={'30px'}>
        <Typography variant='h3'>
          Consultorías
        </Typography>
        <Typography variant='body1'>
          ¿No sabes por dónde empezar? Déjanos ayudarte.
        </Typography>
      </ServicesContainer>
    </Grid>
  )
}

const Home = () => {
  return (
    <div>
      <ImageContainer>
        <StyledMainImage src={accessibleImage} role="presentation" alt="" />
      </ImageContainer>
      <StyledTypography variant="h2" paddingTop="30px">
        ¿Por qué accessibilidad?
      </StyledTypography>
      <WhyColumns/>
      <StyledTypography variant="h2" padding="30px">
        Nuestros servicios
      </StyledTypography>
      <Services/>
      <Grid container sx={{ padding: { xs: '1% 5%', md: '3rem 10rem' }}}>
        <QuoteContainer item xs={12} md={6}>
          <q>
            El poder de la web se encuentra en su universalidad. El acceso para cualquier persona, independientemente de las discapacidades, es un aspecto esencial.
          </q>
          <p>
            – TIM BERNERS-LEE, Creador de la World Wide Web
          </p>
        </QuoteContainer>
        <Grid item alignSelf={'center'} xs={12} md={6}>
          <StyledTypography variant="h2">
            Conversemos
          </StyledTypography>
          <StyledTypography variant='body1' marginBottom={'10px'}>
            Sin compromisos, sin letra chica. Queremos ayudarte
          </StyledTypography>
          <SpacedLink href={`mailto:${horacioMail}`} target="_blank" aria-label="whatsapp">
            <Mail/>
          </SpacedLink>
          <SpacedLink href={whatsappLink} target="_blank" aria-label="whatsapp">
            <WhatsApp/>
          </SpacedLink>
          <SpacedLink href={twitterLink} target="_blank" aria-label="twitter">
            <Twitter/>
          </SpacedLink>
          <SpacedLink href={linkedinLink} target="_blank" aria-label="linkedin">
            <LinkedIn/>
          </SpacedLink>
        </Grid>
      </Grid>
      <Grid>
        
      </Grid>
    </div>
  )
}

export default Home;

const ImageContainer = styled.div`
  background-color: ${colors.secondary3};
`

const ServicesContainer = styled(Grid)`
  background-color: ${colors.secondary3};
`

const StyledImg = styled.img`
  aspect-ratio: 4/3;
  height: 200px;
`

const StyledMainImage = styled.img`
  max-width: 90vW;
  aspect-ratio: 4/3;
`

const QuoteContainer = styled(Grid)`
  background-color: ${colors.primary};
  color: ${colors.white};
  text-align: left;
  padding: 3rem;
  margin: 1rem;
`

const SpacedLink = styled(Link)`
  padding: 1rem;
`

const StyledTypography = styled(Typography)`
  @media (prefers-color-scheme: dark) {
    &, .MuiLink-root{
      color: white !important;
    }
}
`