import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { colors } from 'theme';
import Link from '@mui/material/Link';
import { wcagLink } from 'appConstants';
import { useState } from 'react';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type EntryCardProps = {
    imageURL: string
    imageAlt: string
    brief: string
    content: () => React.ReactNode
}

const corporationURL = 'https://www.ciudadaccesible.cl/'
const corporationSource = 'https://www.ciudadaccesible.cl/discapacidad-en-chile/estadisticas/#:~:text=Discapacidad%20en%20Chile%20%E2%80%93%20Estudios%20y%20Encuestas%3A&text=Estudio%20Nacional%20de%20Discapacidad%20(Endisc,es%20decir%202.836.818%20personas.)'
const wcagConformance ='https://www.w3.org/WAI/WCAG2A-Conformance'
const quickRef = 'https://www.w3.org/WAI/WCAG21/quickref/'
const datoProps = 'https://accessibility.18f.gov/hidden-content/'
const contrastChecker = 'https://webaim.org/resources/contrastchecker/'
const axeCore = 'https://github.com/dequelabs/axe-core'
const lighthouse = 'https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk/related'
const axeDevtools = 'https://chrome.google.com/webstore/detail/axe-devtools-web-accessib/lhdoppojpmngadmnindnejefpokejbdd'
const wave= 'https://chrome.google.com/webstore/detail/wave-evaluation-tool/jbbplnpkjmmeebjpijfedlgcdilocofh'
const cypressAxe = 'https://www.npmjs.com/package/cypress-axe'
const jestAxe = 'https://github.com/nickcolley/jest-axe'
const entries: EntryCardProps[] = [
  {
    imageURL: 'https://miro.medium.com/max/1360/1*kocnLpxiUR7-e3dPgFJt3g.png',
    imageAlt: '',
    brief: '¿Por qué accessibilidad?',
    content: ()=> {
      return (
        <>
          <Typography variant="body1">
            La Accesibilidad web (o A11y por simplicidad) tiene relación con la facilidad con la que se puede acceder a algo (valga la redundancia). Esta idea en general es pensada por y para quienes poseemos las capacidades sensoriales más comunes (vista, audición, tacto). Pero es importante recordar que los usuarios son múltiples, y así como no existe una persona promedio, nuestros usuarios no son promedio. Existen usuarios altos, usuarios bajos, usuarios gordos, usuarios flacos, usuarios ciegos, usuarios sordos, usuarios sin extremidades, etc.
            <br/>
            De acuerdo a la <Link href={corporationURL} target="_blank">Corporación Ciudad accessible</Link> actualmente, solo en Chile al año 2015, se considera que un 16,7% de la población posee algún grado de discapacidad, lo que se traduce en casi 3 millones de personas, 3 millones de potenciales usuarios (<Link href={corporationSource} target="_blank">fuente</Link>)
            <br/>
            Diseño y desarrollo tienen que tener toda la gamma de usuarios que potencialmente puedan usar su aplicación considerados. No considerarlos y hacer que una pagina no pueda ser “escuchada” por ejemplo, implica que un usuario ciego no podrá usar nuestro producto
          </Typography>
        </>
      )
    }
  },
  {
    imageURL: 'https://miro.medium.com/max/1346/1*zbYo9BRD1BUlW3p4seLncA.png',
    imageAlt: '',
    brief: '¿Dónde empezar?',
    content: ()=> {
      return (
        <>
        <Typography variant="body1">
          Existen distintos estándares que pueden ser considerados para clasificar un producto como accesible o no, pero el gold estándar son los lineamientos de la <Link href={wcagLink} target="_blank">WCAG (Web Content Accessibility Guidelines)</Link>, desarrollados por la World Wide Web Consortium (W3C) Web Accessibility Initiative (WAI)
          <br/>
          los lineamientos de WCAG tienen distintos niveles de adherencia, conocidos como los <Link href={wcagConformance} target="_blank">niveles A, AA y AAA</Link>:
          <br/>

          Nivel A: Mínimo
          <br/>

          Nivel AA: incluye los requerimientos de tipo A y AA. Éste es el nivel de adherencia mas común y al que en general las empresas aspiran
          <br/>

          Nivel AAA: Incluye todos los requerimientos de tipo A, AA, y AAA
          <br/>
          La W3C desarrolló una <Link href={quickRef} target="_blank">referencia rápida</Link> para evaluar la A11y de cada producto. En esta referencia podemos ver cada lineamiento señalando y qué tipo de nivel de adherencia representa (A, AA o AAA). No obstante, es importante considerar que según el motor de accesibilidad que se utilice (VoiceOver o NVDA como screen readers, por ejemplo) y el tipo de usuario al que se apunte (es distinta la experiencia de un usuario ciego a otro que no puede utilizar el mouse pero si puede ver), los resultados pueden variar
        </Typography>
        </>
      )
    }
  },
  {
    imageURL: "https://miro.medium.com/max/1200/1*CTE0tk3HGY6gAowFGTTnnQ.jpeg",
    imageAlt: '',
    brief: '¿Cómo evalúo y/o mejoro la A11y de mi producto actual? (Parte 1)',
    content: ()=> {
      return (
        <>
          <Typography variant="body1">
            Primero, es importante reforzar que la accesibilidad debiese estar en tu mente en cada paso del desarrollo y del diseño del producto. No obstante, si quieres evaluar/mejorar tu producto actual, ésta es una buena base sobre la cual la mayor parte de los usuarios pueden interactuar
          </Typography>
          <Typography variant="h3">
            Checklist de A11y:
          </Typography>
          <Typography variant="body1">
            <Typography variant="h4">Skip to main:</Typography> Imagina que eres ciego y quieres entrar a Amazon. ¿Te gustaría tener que pasar escuchando TODOS los links que tiene la página en el header? Nop. Por lo mismo, se recomienda que al entrar a la pagina, la primera opción disponible sea un “Saltar al contenido principal” para así no necesitar pasar por todo el encabezado. Este botón debe estar oculto para el usuario sin necesidades de accesibilidad, pero visible ante la navegación por teclado en la página (inténtalo, entra a Amazon e intenta moverte solo con la tecla TAB)
            <br/>
            <Typography variant="h4">Navegación por teclado:</Typography> El usuario debe poder interactuar con tu producto aunque esté utilizando sólo el teclado para moverse. Por ello, todos los Call-to-action deben ser accesibles con la tecla TAB y los eventos deben poder ser accionados a través del teclado también
            <br/>
            <Typography variant="h4">Si el contenido está escondido visualmente, también debe estar escondido para los Screen readers:</Typography> Si tienes un producto donde, por ejemplo, un menú se expande/esconde según el ancho del viewport. Al pasar con un screen reader, éste debería leer el contenido solo si está visible para todos los usuarios. (dato: <Link href={datoProps} target="_blank">Qué props usar en cada caso</Link>)
            <br/>
            <Typography variant="h4">Imágenes con un texto alternativo:</Typography> Las imágenes deben contar con un texto alternativo y explicativo de la misma si es que ésta imagen da algún valor más que estético. En caso de que la imagen sea solo decorativa, no debe ser leída ni recorrida por el Screen Reader. Si estás diseñando un producto y usas imágenes que son relevantes para el contenido, infórmale al desarrollador qué texto alternativo debiesen (o no) contener
          </Typography>
        </>
      )
    }
  },
  {
    imageURL: "https://miro.medium.com/max/1400/1*dAW9LsGkYElEpQoPeB9iqA.jpeg",
    imageAlt: '',
    brief: '¿Cómo evalúo y/o mejoro la A11y de mi producto actual? (Parte 2)',
    content: ()=> {
      return (
        <>
          <Typography variant="body1">
            Luego de haber conversado un poco respecto a como evaluar la accesibilidad de tu web, ahora continuamos con el checklist
          </Typography>
          <Typography variant="h3">
            Checklist de A11y (parte 2):
          </Typography>
          <Typography variant="body1">
            <Typography variant="h4">Uso de Landmarks:</Typography> Los landmarks son las “partes” de tu página, como header, main o footer. En general las páginas se encuentran organizadas de una manera mas o menos lógica dentro de estos Landmarks (por ejemplo, en general el header posee un logo que permite ir a la página principal). Si hacemos un uso adecuado de los landmarks y marcamos cada “parte” de nuestro producto como corresponde, navegar será mucho mas sencillo para cualquier usuario que necesite algún tipo de asistencia
            <br/>
            <Typography variant="h4">Heading ordenados:</Typography> El contenido de una página siempre debe ir de la idea más general (título) a una más específica (subtítulo) a una más específica (sub-subtitulo), y así. Al utilizar headings ordenadamente en el HTML le ayudamos al usuario a entender cómo está organizada la información en nuestro producto. Te imaginas recorrer un articulo de Wikipedia donde los títulos y/o los subtítulos no tienen un orden/estilo específico? eso le pasa muchas veces a los usuarios de Screen Reader. Por lo mismo, si estas usando un <code>h1</code>, lo que corresponde después es un <code>h2</code>, no un <code>h3</code>, porque un <code>h3</code> indicaría una MAYOR especificidad en el contenido, lo cual no puede suceder sin un nivel de especificidad <code>h2</code> antes
            <br/>
            <Typography variant="h4">Color y Contraste:</Typography> Éste es quizás uno de los puntos más difíciles de conseguir, porque cambiar los colores de una página para que tengan el contraste recomendado significa cambiar los colores del branding de nuestros clientes. Pero como lineamientos generales, la recomendación es que los colores tengan un contraste de al menos 4.5:1 en el nivel AA y de 7:1 en el nivel AAA. Existen herramientas en la web para comprobar el nivel de contraste de tus colores, como <Link href={contrastChecker} target="_blank">ésta</Link>. Si tu cliente tiene colores de branding específicos y no los va a cambiar, siempre levanta la inquietud y explícale que es un problema de accesibilidad, después de todo, la decisión final pasa por él.
            <br/>
            <Typography variant="h4">Imágenes con un texto alternativo:</Typography> Las imágenes deben contar con un texto alternativo y explicativo de la misma si es que ésta imagen da algún valor más que estético. En caso de que la imagen sea solo decorativa, no debe ser leída ni recorrida por el Screen Reader. Si estás diseñando un producto y usas imágenes que son relevantes para el contenido, infórmale al desarrollador qué texto alternativo debiesen (o no) contener
          </Typography>
        </>
      )
    }
  },
  {
    imageURL: "https://miro.medium.com/max/1400/1*NOciHUlHDZVc4Cmh2J0WGw.jpeg",
    imageAlt: '',
    brief: 'Herramientas de desarrollo disponibles',
    content: ()=> {
      return (
        <>
          <Typography variant="body1">
            Existe una gamma de herramientrtas que pueden ayudar a desarrolladores y diseñadores para asegurar la accesibilidad de su producto, no obstante, es importante mencionar que el uno de cada una dependerá de cada objetivo y que siempre es importante mantener las evaluaciones manuales
          </Typography>
          <Typography variant="body1">
            <b><Link href={axeCore}>Axe-core:</Link></b> Axe es un motor de testing de accesibilidad, y el más utilizado. Utiliza las WCAG nivel A y AA además de otras buenas prácticas en el desarrollo
            <br/>
            <b><Link href={lighthouse}>Lighthouse plugin:</Link></b> Este plugin para navegador corre el motor de axe y con él evalúa la pagina que tienes actualmente desplegada. Puede evaluar para versiones mobiles y de escritorio y también puede entregar informes de performance entre otras cosas
            <br/>
            <b><Link href={axeDevtools}>Axe DevTools plugin:</Link></b> También es un plugin para navegador basado en axe-core que entrega un informe exclusivo de accesibilidad y de forma mas detallada que lighthouse, no obstante, la interfaz es menos amigable
            <br/>
            <b><Link href={wave}>Wave plugin:</Link></b> Plugin para navegadores que te permite ver in-situ los posibles errores en tu producto (y entrega sugerencias!). Si no estas familiarizado con accesibilidad, puede ser un poco agobiante de ver, pero una vez que conoces los conceptos básicos, es muy útil
            <br/>
            <b><Link href={cypressAxe}>Cypress-axe:</Link></b> Plugin de cypress que permite automatizar el testing de la accesibilidad de cada página de tu aplicación en la medida que Cypress testea las historias de usuario correspondientes
            <br/>
            <b><Link href={jestAxe}>Jest-axe:</Link></b> Plugin para Jest con un comportamiento similar a prettieru otros. No testea accesibilidad en las páginas desplegadas, si no que busca “common issues” en el código. No se considera una herramienta estándar para aumentar la accesibilidad de un producto. Pero es útil saber de su existencia
            <br/>
          </Typography>
        </>
      )
    }
  },
  {
    imageURL: "https://miro.medium.com/max/1090/1*PTzxe6Anhj64Ir4JgxMBPA.png",
    imageAlt: '',
    brief: '¿Por qué se dice “A11y” para decir accesibilidad? ',
    content: ()=> {
      return (
        <>
          <Typography variant="body1">
          Pues En inglés ‘Accessibility’ tiene 13 letras, entonces es:
          <br/>
          1 letra (A) + 11 letras (ccessibilit) + 1 letra (y) = A11y
          Y se utiliza pensando en la similitud de “A11y” con “ally” (aliado) porque la accesibilidad, va más allá que solo sitios web 🙃
          </Typography>
        </>
      )
    }
  }
]
const EntryCard = ({imageURL, imageAlt, brief, content}: EntryCardProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard sx={{ width: 700 }}>
      {
        expanded ? (
        <CardMedia
          component="img"
          height="auto"
          image={imageURL}
          alt={imageAlt}
        />
        ) : (
        <CardMedia
          component="img"
          height="194"
          image={imageURL}
          alt={imageAlt}
        />
        )
      }

      <CardContent>
        <Typography variant="h2">
          {
            brief
          }
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="mostrar más"
        >
          <StyledIcon/>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{textAlign: 'left'}}>
          {
            content()
          }
        </CardContent>
      </Collapse>
    </StyledCard>
  );
}

const Blog = () =>{
  return(
    <Grid container columnSpacing={2} rowSpacing={2} sx={{ padding: { xs: '1% 5%', md: '3rem 10rem' }}}>
      <StyledTypography variant="h1">
        Blog
      </StyledTypography>
    {
      entries.map(entry => {
        return (
          <Grid item xs={12} display="flex" justifyContent='center'>
            <EntryCard {...entry}/>
          </Grid>
        )
      })
    }
    </Grid>
  )
}

export default Blog;

const StyledCard = styled(Card)`
  background-color: ${colors.secondary3} !important;
`;

const StyledIcon = styled(ExpandMoreIcon)`
    color: ${colors.primary} !important;
`;

const StyledTypography = styled(Typography)`
  @media (prefers-color-scheme: dark) {
    &, .MuiLink-root{
      color: white !important;
    }
}
`