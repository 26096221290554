import { createTheme } from "@mui/material"

export const colors = {
  primary: "#332884",
  secondary: "#4E5C93",
  secondary2: "#A3B5D6",
  secondary3: "#CCD7EE",
  greyMediumDark: "#6A6D81",
  white: "#fff",
  error: "#CD1818", 
  success: "#06D6A0",
  warning: "rgb(245, 124, 0)",
}


const theme = createTheme({
  typography: {
    h1: {
      color: colors.primary,
      fontWeight: 700,
      fontSize: '50px',
      lineHeight: '60px',
    },
    h2: {
      color: colors.secondary,
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '40px',
    },
    h3:{
      color: colors.secondary,
      fontWeight: 900,
      fontSize: '30px',
      lineHeight: '40px',
    },
    h4:{
      color: colors.secondary,
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '40px',
    },
    body1: {
      color: colors.greyMediumDark,
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '30px',
      li:{
        textAlign: 'left',
      }
    },
    body2: {
      color: colors.greyMediumDark,
    }
  },
  palette:{
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    }
  },
});

export default theme;