import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import SkipToMain from "./components/SkipToMain";
import './App.css';
import Home from "pages/Home";
import Header from "components/Header";
import Footer from "components/Footer";
import Blog from "pages/Blog";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div />}>
        <SkipToMain />
        <Header/>
        <main style={{minHeight: '80vh'}}>
          <Routes>
            <Route element={<Home/>} path={'/'} />
            <Route element={<Blog />} path={'/blog'} />
          </Routes>
        </main>
        <Footer/>
      </Suspense>
    </div>
  );
}

export default App;
